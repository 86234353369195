<template>
  <Waiting v-if="authenticating" message="Validating your credentials!" />
  <div v-else class="flexcontainer logincontainer">
    <form @submit.prevent="handleSubmit">
      <p class="error" v-if="autherror">{{ autherror }}</p>
      <p>{{ message }}</p>
      <input class="email" type="email" required placeholder="email" v-model="email" />

      <div class="pwcontainer">
        <input class="pw" :type="type" required placeholder="password" v-model="password" />
        <i v-if="eye" class="material-icons visibility" @click="toggle">visibility</i>
        <i v-else class="material-icons visibility" @click="toggle">visibility_off</i>
      </div>

      <label class="chkbx">
        <input type="checkbox" v-model="stayloggedin" >
        <span>Stay logged in (don't tick on a public computer)</span>
      </label>

      <button class="button">Log in</button>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import useAuth from '@/composables/useAuth';

export default {
  name: "LoginForm",
  components: {
  },
  setup() {
    console.log("loginform setup entered");

// EXTERNAL PROPERTIES
    const { login, stayloggedin, autherror } = useAuth();

// DATA
    const email = ref('');
    const password = ref('');
    const message = ref('Please log in');
    const authenticating = ref(false);

    const eye = ref(true);
    const type = ref('password');

// FUNCTIONS
    const toggle = () => {
      console.log('toggle entered');
      eye.value = !eye.value;
      type.value = (eye.value?'password':'text');
    }

    const handleSubmit = async () => {
      console.log('handleSubmit entered');
      authenticating.value = true;
      await login(email.value, password.value);
      authenticating.value = false;
      console.log('handleSubmit exited');
    }

    console.log("loginform setup exited");
// EXPOSED PROPERTIES
    return {
      email,
      password,
      message,
      authenticating,
      autherror,
      eye,
      type,
      stayloggedin,
      handleSubmit,
      toggle
    };
  }
}

</script>

<style scoped>

.logincontainer {
  width: 340px;
  height: 340px;
  margin: 50px auto;

}

form {
  width: 340px;
  height: 400px;
}

  /* form styles */
  .email {
    width: 96%;
    margin: 10px 2%;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #999;
    font-size: 0.9rem;
    padding: 10px;
    box-sizing: border-box;
  }

  .pwcontainer {
    width: 96%;
    margin: 10px 2%;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #999;

    display: flex;
    align-items: center;
  }
  .pw {
    padding: 10px;
    width: 100%;
    border: 0;
    outline: none;
    color: #999;
  }
  i {
    margin: 0 10px;
    color: #999;
    cursor: default;
  }

  .button {
    margin: 40px 2% 10px 2%;
  }

  .chkbx {
    font-size: 0.8rem;
    margin-left: 40px;
  }

  .chkbx input {
    width: 20px;
  }

  p {
    padding: 10px;
    text-align: center;
  }








</style>

