<template>
  <nav class="navbar">
    <div class="brand-title">
      <div @click="restartApp">{{ title }}</div>
      <div v-if="loggedinuser" class="liuser">{{ name }} ({{ rolename }})</div>
    </div>
    <a v-if="!active" class="toggle-button" @click="active = !active">
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
    </a>
    <a v-else class="toggle-button" @click="active = !active">
      <span class="close">X</span>
    </a>
    <div :class="{ active: active }" class="navbar-links">
      <ul>
        <li><router-link :to="{name: 'member events'}">Home</router-link></li>
        <li v-if="loggedinuser && loggedinuser.role != 'member'"><router-link :to="{name: 'admin events'}">Manage Events</router-link></li>
        <li v-if="loggedinuser && loggedinuser.role != 'member'"><router-link :to="{name: 'admin members'}">Manage Members</router-link></li>
        <li><a href=# @click="changeMemberPassword">Change password</a></li>
        <li><a href=# @click="doLogout">Logout</a></li>
      </ul>
    </div>
  </nav>
</template>
<router-link class="link" :to="{name: 'admin events'}">Back to list</router-link>

<script>

import {computed, ref} from 'vue';
import useSettings from "@/composables/useSettings";
import useAuth from "@/composables/useAuth";
import useMembers from "@/composables/useMembers";

export default {
name: "Navbar",
  setup() {
    console.log("navbar setup entered");

// EXTERNAL PROPERTIES
    const { title, homeurl, roles } = useSettings();
    const { loggedinuser, logout } = useAuth();
    const { changePassword } = useMembers();

// COMPUTED PROPERTIES
    const name =
        computed(() => {
          if (loggedinuser.value) {
            return loggedinuser.value.firstname + ' ' + loggedinuser.value.lastname.charAt(0);
          } else {
            return "";
          }
        });
    const rolename =
        computed(() => {
          if (loggedinuser.value) {
            return roles[loggedinuser.value.role];
          } else {
            return "";
          }
        });

// DATA
    const active = ref(false);

// FUNCTIONS
    const changeMemberPassword = async () =>{
      console.log('changeMemberPassword entered');

      const newpassword = prompt('Enter password between 8 and 12 characters');
      console.log('NEW PASSWORD:', newpassword)

      if (newpassword) {
        const alertmessage = await changePassword(loggedinuser.value.id, newpassword, false);
        console.log('ALERT MESSAGE:', alertmessage);

        alert(alertmessage);
        restartApp();
      }
    }

    const restartApp = () => {
      window.location.replace(homeurl);
    }

    const doLogout = async () =>{
      console.log('Logging out');
      await logout();
      window.location.replace(homeurl);
    }

    return {
      title,
      active,
      loggedinuser,
      rolename,
      name,
      doLogout,
      restartApp,
      changeMemberPassword
    };
  }
}
</script>


<style scoped>

.navbar {
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  background-color: #cccccc;
  border-bottom: darkblue 3px solid;
  color: darkblue;
}

.brand-title {
  font-size: 1.2rem;
  margin: .5rem;
  cursor: pointer;
}

.liuser {
  margin-left: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
}

.navbar-links {
height: 100%;
}

.navbar-links ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  list-style: none;
}

.navbar-links li a {
  display: block;
  text-decoration: none;
  color: darkblue;
  padding: 1rem;
}

.navbar-links li:hover {
  background-color: #eee;
}

.toggle-button {
  position: absolute;
  top: .75rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: darkblue;
  border-radius: 10px;
}

.toggle-button .close {
  margin: -5px auto;
  font-weight: bold;
  font-size: 1.5rem;
  color: darkblue;
}

@media (max-width: 800px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .toggle-button {
    display: flex;
  }

  .navbar-links {
    display: none;
    width: 100%;
  }

  .navbar-links ul {
    width: 100%;
    flex-direction: column;
  }

  .navbar-links ul li {
    text-align: center;
  }

  .navbar-links ul li a {
    padding: .5rem 1rem;
  }

  .navbar-links.active {
    display: flex;
  }
}

</style>