<template>

  <div v-if="memberpredictedattendance !== null">
    <div class="inline">
      <input
          type="radio"
          v-model="memberpredictedattendance"
          :value="2"
          :name="'evmempredatt_' + eid +'_'+mid"
          @change="doChange"
      />
      <label>Yes</label>
    </div>

    <div class="inline">
      <input
          type="radio"
          v-model="memberpredictedattendance"
          :value="1"
          :name="'evmempredatt_' + eid +'_'+mid"
          @change="doChange"
      />
      <label>No</label>
    </div>

    <div class="inline">
      <input
          type="radio"
          v-model="memberpredictedattendance"
          :value="3"
          :name="'evmempredatt_' + eid +'_'+mid"
          @change="doChange"
      />
      <label>Unsure</label>
    </div>


    <div class="inline invisible">
      <input
          type="radio"
          v-model="memberpredictedattendance"
          :value="0"
          :name="'evmempredatt_' + eid +'_'+mid"
          @change="doChange"
          disabled
      />
      <label>No data</label>
    </div>

  </div>

</template>

<script>

import useAttendance from '@/composables/useAttendance';
import {ref} from "vue";

export default {
  name: "MemberEventPredictedAttendance",
  props: {
    eid: String,
    mid: String,
    mempredatt: Object
  },
  emits: [
    'predattchanged'
  ],

  setup (props, { emit }) {
    console.log('MemberEventPredictedAttendance setup entered', props);

// EXTERNAL PROPERTIES
    const { changePredictedAttendanceState } = useAttendance();

// DATA
    const memberpredictedattendance = ref(0);

// FUNCTIONS
    const doChange = async () => {
      console.log('doChange entered');

      // send the new value up the stack so it can be used to update the data
      const newvalue = await changePredictedAttendanceState(props.eid, props.mid, memberpredictedattendance.value);
      emit('predattchanged', newvalue);

      console.log('doChange exited');
    }

// ENTRYPOINT
    if (props.mempredatt && props.mempredatt.available) {
      memberpredictedattendance.value = props.mempredatt.available
    }
    console.log('MemberEventPredictedAttendance setup exited')
// EXPOSED PROPERTIES
    return {
      memberpredictedattendance,
      doChange
    };
  }
}
/* the mempredatt object !!!maybe null!!! or
    {mid, name, position, available (1-No, 2-Yes)}
*/

</script>

<style scoped>
.inline {
  display: inline-block;
  padding: 0 5px;
}

.invisible {
  display: none;
}
</style>