<template>

  <Waiting v-if="loading" message="loading events..." />

  <div v-else>

    <div class="pageheader">Forthcoming events</div>
    <div v-for="(event, index) in eventlistformember" :key="index">
      <MemberSingleEvent :event="event" @eventchanged="updateEvent($event, index)" @predattchanged="updatePredictedAttendanceForMember($event, index)" />
    </div>

  </div>

</template>

<script>
import MemberSingleEvent from '@/components/events/MemberSingleEvent';
import useEvents from "@/composables/useEvents";
import { ref } from 'vue';

export default {
  name: 'MemberEventsContent',
  components: {
    MemberSingleEvent
  },
  setup() {
    console.log('MemberEventsContent setup entered');

// EXTERNAL PROPERTIES
    const { getEventListForMember } = useEvents();

// DATA
    const loading = ref(null);
    const eventlistformember = ref([]);

// FUNCTIONS
    const updateEvent = (newvalue, index) => {
      console.log('updateEvent entered', index, newvalue);
      console.log('Old value',eventlistformember.value[index])
      eventlistformember.value[index] = newvalue;
      console.log('After uodate',eventlistformember.value[index])
      console.log('updateEvent exited');
    }

    const updatePredictedAttendanceForMember = (newvalue, index) => {
      console.log('updatePredictedAttendanceForMember entered');
      eventlistformember.value[index].memberpredictedattendance = newvalue;
      console.log('updatePredictedAttendanceForMember exited');
    }

    const loadData = async () => {
      console.log('loadData entered');
      loading.value = true;
      const today = new Date();
      const events = await getEventListForMember();
      events.forEach((event) => {
        if (event.status != 'enquiry' || (event.urgentuntil != null && event.urgentuntil >= today.toISOString().slice(0,10))) {
          eventlistformember.value.push(event);
        }
      })
      loading.value = false;
      console.log('loadData exited');
    }

// ENTRY POINT
    loadData();

    console.log('MemberEventsContent setup exited');
// EXPOSED PROPERTIES
    return {
      loading,
      eventlistformember,
      updateEvent,
      updatePredictedAttendanceForMember
    };
  }

};

</script>

<style scoped>


</style>
