<template>
  <Waiting v-if="loading" message="loading details..." />
  <div v-else>
    <div class="top container detailscontainer">
      <p><span class="label">Date: </span><span> {{ formatteddate }} </span></p>
      <p><span class="label">Start time: </span><span> {{ formattedstart }} </span></p>
      <p><span class="label">End time: </span><span> {{ formattedend }} </span></p>
      <p><span class="label">Title: </span><span> {{ event.title }} </span></p>
      <p><span class="label">Venue: </span><span> {{ event.location }} </span></p>
      <p><span class="label">Event type: </span><span class="capfirst" :class="event.type"> {{ event.type }} </span></p>
      <p><span class="label">Event status: </span><span class="capfirst" :class="event.status+'-text'"> {{ event.status }} </span></p>
      <p><span class="label">Info: </span><span> {{ event.info }} </span></p>
    </div>

    <div class="bottom container detailscontainer">
      <h1>Availability summary: </h1>

      <div class="can">
        <h2>Can attend:</h2>
        <div v-for="(player) in canAttend" :key="player.name">
          <p class="info">{{ player.name }} ({{ player.position}} )</p>
        </div>
      </div>

      <div class="cannot">
        <h2>Cannot attend:</h2>
        <div v-for="(player) in cannotAttend" :key="player.name">
          <p class="info">{{ player.name }} ({{ player.position}} )</p>
        </div>
      </div>

      <div class="unsure">
        <h2>Unsure:</h2>
        <div v-for="(player) in unsure" :key="player.name">
          <p class="info">{{ player.name }} ({{ player.position }})</p>
        </div>
      </div>

      <div class="dontknow">
        <h2>No data:</h2>
        <div v-for="(player) in noData" :key="player.name">
          <p class="info">{{ player.name }} ({{ player.position }})</p>
        </div>
      </div>

   </div>
  </div>

</template>

<script>
import useMembers from "@/composables/useMembers";
import useEvents from "@/composables/useEvents";
import useAttendance from "@/composables/useAttendance";
import Waiting from '@/components/global/Waiting';
import {computed, ref} from 'vue';

export default {
  name: "MemberEventDetails",
  props: {
    eid: String,
    mid: String
  },
  components: {
    Waiting,
  },
  emits: [
      'eventchanged'
  ],
  setup(props, { emit }) {
    console.log('MemberEventDetails setup entered');

// EXTERNAL PROPERTIES
    const { getMemberList } = useMembers();
    const { getEventForMember, getEventDetails } = useEvents();
    const { getPredictedAttendance } = useAttendance();

// DATA
    const event = ref(null);
    const memberlist = ref(null);
    const memberavaillist = ref(null);
    const depavaillist = ref(null);
    const loading = ref(null);
    const formatteddate = ref(null);
    const formattedstart = ref(null);
    const formattedend = ref(null);
    const canAttend = ref(null);
    const cannotAttend = ref(null);
    const unsure = ref(null);
    const noData = ref(null);

// FUNCTIONS

    const loadData = async () => {
      console.log('loadData entered');
      loading.value = true;
      // retrieve the details for the event for the current logged in user
      // the predicted attendance returned is for just this member and this event
      // there is always a predicted attendance object, even if the field in the database is null
      // there is also the member's usual position
      // This is essential to make sure data is not lost when emitting eventchanged

      event.value = await getEventForMember(props.eid, props.mid)
//      event.value = await getEventDetails(props.eid)

      // Now get all the predicted attendance for the event as this is mising from the previous call
      const eventpredictedattendance = await getPredictedAttendance(props.eid);

      // only format the date and time after the data has been retrieved
      formatteddate.value = new Date(event.value.date).toDateString();
      formattedstart.value = event.value.starttime.substring(0,5);
      formattedend.value = (event.value.endtime?event.value.endtime.substring(0,5):'');

      // make sure the values of depavail and memberavail are not null
      let depavail = [];
      let memberavail = [];
      if (eventpredictedattendance !== null) {
        depavail = eventpredictedattendance.depavail;
        memberavail = eventpredictedattendance.memberavail;
      }

      // get the list of current members
      memberlist.value = await getMemberList();

      // create an availability list for all current members
      // each being an object containing id, name, position and available
      let availabilitylist = [];
      memberlist.value.forEach((member) => {
        const recordedmemberavail = memberavail.find((entry) => {
          return entry.mid == member.id;
        })
        if (recordedmemberavail) {
          availabilitylist.push({
            position: (recordedmemberavail.position?recordedmemberavail.position: member.position),
            available: recordedmemberavail.available,
            id: member.id,
            name: member.firstname + ' ' + member.lastname
          });
        } else {
          availabilitylist.push({
            position: member.position,
            available: "0",
            id: member.id,
            name: member.firstname + ' ' + member.lastname
          });
        }
      })
      memberavaillist.value = availabilitylist;

      // get the deps by position - there may be none
      // depavail is an array of objects containing position and name - name may contain more than one dep
      depavaillist.value = depavail;

      loading.value = false;

      canAttend.value = memberavaillist.value.filter((entry) => {
          return entry.available == 2;
        });

      cannotAttend.value = memberavaillist.value.filter((entry) => {
          return entry.available == 1;
        });

      noData.value = memberavaillist.value.filter((entry) => {
          return entry.available == 0;
        });

      unsure.value = memberavaillist.value.filter((entry) => {
        return entry.available == 3;
      });


      // send the event back up the stack in case it has been changed
      // this is the event as it was passed down as a prop
      // it does not contain all the predicted attendance data
      emit('eventchanged', event.value);

      console.log('loadData exited');
    }
// ENTRY POINT
    loadData();
    console.log('MemberEventDetails setup exited');
// EXPOSED PROPERTIES
    return {
      event,
      formatteddate,
      formattedstart,
      formattedend,
      depavaillist,
      loading,
      canAttend,
      cannotAttend,
      unsure,
      noData
    }
  }
}

</script>

<style scoped>

.detailscontainer {
  flex-direction: column;
}

.label {
  display: inline-block;
  width: 6rem;
}

.info {
  margin-left: 6.2rem;
}
.bottom h1 {
  font-size: 1.1rem;
}

.bottom h2 {
  font-style: italic;
  font-weight: bolder;
  font-size: 1rem;
  margin-top: 10px;
}

.bottom p {
  font-size: 0.9rem;
}

.can {
  color: green;

}
.cannot {
  color:red;
}
.unsure {
  color: #888888;
}
.dontknow {
  color: black;
}
.dep {
  color: blue;
}


</style>