<template>

  <div>
    <div class="pageheader">
      <p> {{ formatteddate }}  {{ formattedstart }}  {{ event.title }}</p>
    </div>

    <div class="flexcontainer formcontainer">
      <form @submit.prevent="handleSubmit">
        <div v-if="validationerror != ''" class="error">{{ validationerror }}</div>

        <div class="formitem">
          <div class="left">
            Number to add:
          </div>

          <div class="right">
            <input class="forminput" type="number" min="1" v-model="count"/>
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Date:
          </div>

          <div class="right">
            <datepicker-lite
                :value-attr="event.date"
                @value-changed="dateSelected"
                class-attr="forminput"
                placeholder-attr="Select the first date"
                :locale="dateformat"
            />
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Start time:
          </div>

          <div class="right">
            <input class="forminput" type="text" placeholder="Enter a time hh:mm (00:00 to 23:59)" v-model.trim="event.starttime" />
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            End time (optional):
          </div>

          <div class="right">
            <input class="forminput" type="text" placeholder="Enter a time hh:mm (00:00 to 23:59)" v-model.trim="event.endtime" />
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Event title:
          </div>

          <div class="right">
            <textarea class="forminput" v-model.trim="event.title" ></textarea>
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Event venue:
          </div>

          <div class="right">
            <textarea maxlength="255" class="forminput" v-model.trim="event.location" ></textarea>
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Event type:
          </div>

          <div class="right">
            <select class="forminput capfirst" v-model.trim="event.type">
              <option class="capfirst" v-for="(type) in typevals"
                  :value="type"
                  :key="type"
                  :selected="type===event.type">
                {{ type }}
              </option>
            </select>
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Event status:
          </div>

          <div class="right">
            <select class="forminput capfirst" v-model.trim="event.status">
              <option
                  v-for="status in statusvals"
                  :value="status"
                  :key="status"
                  :selected="status===event.status">
                {{ status }}
              </option>
            </select>
          </div>
        </div>



        <input class="button" type="submit" value="Submit" />
        <router-link class="button" :to="{name: 'admin events'}">Cancel</router-link>
      </form>

    </div>

  </div>
</template>

<script>
import useSettings from '@/composables/useSettings';
import useEvents from '@/composables/useEvents';
import { ref } from 'vue';
import DatepickerLite from 'vue3-datepicker-lite';
import { useRouter } from 'vue-router';

export default {
  name: "AdminEventDetailsContent",
  components: {
    DatepickerLite
  },

  setup() {
    console.log('AdminEventBulkContent setup entered');

// EXTERNAL PROPERTIES
    const { eventtypes, eventstatuses } = useSettings();
    const { changeEvent } = useEvents();
    const router = useRouter();

// COMPUTED PROPERTIES

// DATA
    const count = ref(1);
    const typevals = ['Select an event type'].concat(eventtypes);
    const statusvals = ['Select a status'].concat(eventstatuses);
    const event = ref(null);
    const formatteddate = ref(null);
    const formattedstart = ref(null);
    const errors = ref([]);
    const validationerror = ref('');

    const dateformat =   {format: "YYYY-MM-DD"};

// FUNCTIONS
    const dateSelected = (value) => {
      console.log('dateSelected entered');
      event.value.date = value;
      formatteddate.value = new Date(event.value.date).toDateString();
      console.log('dateSelected exited');
    }

    const urgentuntilSelected = (value) => {
      console.log('urgentuntilSelected entered');
      event.value.urgentuntil = value;
      console.log('urgentuntilSelected exited');
    }

    const handleSubmit = async (submitevent) => {
      console.log('handleSubmit entered');
      console.log('COUNT', count);
      console.log('EVENT VALUE', event.value);
      errors.value = [];
      validationerror.value = '';
      validateInput();
      if (errors.value.length>0) {
        validationerror.value =  errors.value.join('; ');
        return false
      } else {
        let eventdate = new Date(event.value.date);
        while (count.value > 0) {
          console.log("XXXXXXXX", count.value)
          await changeEvent(0, event.value);
          router.push({name: 'admin events'});
          count.value -= 1;
          eventdate.setDate(eventdate.getDate() + 7);
          event.value.date = eventdate.toISOString().split('T')[0]
        }
      }
      console.log('handleSubmit exited');
    }

    const validateInput = () => {
      console.log('validateInput entered');
      if (event.value.date == '') {
        errors.value.push('You must provide a date');
      }
      if (event.value.starttime == '') {
        errors.value.push('You must provide a start time');
      } else {
        const starttimebits = event.value.starttime.split(':');
        if (
            starttimebits.length != 2 ||
            isNaN(starttimebits[0]) ||
            starttimebits[0] < 0 ||
            starttimebits[0] > 23 ||
            isNaN(starttimebits[1]) ||
            starttimebits[1] < 0 ||
            starttimebits[1] > 59
        ) {
          errors.value.push('Invalid starttime format');
        } else {
          //starttime is valid
          if (!(event.value.endtime == '' || event.value.endtime == null)) {
            const endtimebits = event.value.endtime.split(':');
            if (
                endtimebits.length != 2 ||
                isNaN(endtimebits[0]) ||
                endtimebits[0] < 0 ||
                endtimebits[0] > 23 ||
                isNaN(endtimebits[1]) ||
                endtimebits[1] < 0 ||
                endtimebits[1] > 59
            ) {
              errors.value.push('Invalid endtime format');
            } else {
              if (event.value.starttime > event.value.endtime) { //character compare
                errors.value.push('Starttime is after endtime');
              }
            }
          }
        }
      }
//      if (event.value.urgentuntil != '' && event.value.urgentuntil < new Date().toISOString().slice(0, 10)) {
//        errors.value.push('Urgent until date is in the past');
//      }
      if (event.value.title == '') {
        errors.value.push('You must provide an event title');
      }
      if (event.value.location == '') {
        errors.value.push('You must provide an event location');
      }
      if (event.value.type == 'select an event type') {
        errors.value.push('You must select an event type');
      }
      if (event.value.status == 'select a status') {
        errors.value.push('You must select an event status');
      }
      console.log('validateInput exited');
    }

    const loadData = () => {
      console.log('loadData entered');

      // new event
      event.value = {
        date: '',
        starttime: '',
        endtime: null,
        title: '',
        location: '',
        type: 'Select an event type',
        status: 'Select a status',
        urgentuntil: null,
        info: null
      }
      formatteddate.value = 'New Bulk';
      formattedstart.value = 'Event';

      console.log('loadData exited');
    }

// ENTRY POINT
    loadData();

    console.log('AdminEventDetailsContent setup exited');
    return {
      count,
      event,
      formatteddate,
      formattedstart,
      dateformat,
      typevals,
      statusvals,
      validationerror,
      dateSelected,
      urgentuntilSelected,
      handleSubmit
    };

  }

}
// EXTERNAL PROPERTIES
// COMPUTED PROPERTIES
// DATA
// FUNCTIONS
// ENTRY POINT
// EXPOSED PROPERTIES

</script>

<style>

.forminput {
  width: 100%;
}

</style>

<style scoped>

.formcontainer {
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
}

.formitem {
  display: flex;
}

.left {
  margin: 5px 5px 0 0 ;
  padding: 5px;
  background: #f0f0f0;
  flex: 0 0 28%;
}

.right {
  margin: 5px 0 0 5px;
  padding: 5px;
  flex: 0 0 70%;
  max-width: 70%;
  background: #f0f0f0;
}

.links {
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}
.link {
  cursor: pointer;
}

.linkitem {
  overflow-wrap: break-word;
  font-weight: bold;
}

.atlinks .button {
  width: auto;
}
</style>

