<template>

  <Waiting v-if="loading" message="loading events..." />

  <div v-else>

    <div class="pageheader">Manage Forthcoming events</div>
    <button class="button"><router-link class="link" :to="{name: 'event details', params: {eid: 0 } }">Create new event</router-link></button>
    <button class="button"><router-link class="link" :to="{name: 'event bulk' }">Bulk create new event</router-link></button>

    <div v-for="(event, index) in eventlist" :key="index">
      <AdminSingleEvent :event="event"
        @urgentchanged="updateUrgent($event, index)"
      />
    </div>

  </div>

</template>

<script>
import AdminSingleEvent from '@/components/events/AdminSingleEvent';
import useEvents from "@/composables/useEvents";
import {ref} from 'vue';

export default {
  name: 'AdminEventsContent',
  components: {
    AdminSingleEvent,
  },
  setup() {
    console.log('AdminEventsContent setup entered');

// EXTERNAL PROPERTIES
    const { getEventList } = useEvents();

// DATA
    const eventlist = ref([])
    const pasteventlist = ref([])
    const loading = ref(null)

// FUNCTIONS
    const updateUrgent = (newvalue, index) => {
      console.log('updateUrgent entered');
      eventlist.value[index].urgentuntil = newvalue;
      console.log('updateUrgent exited');
    }

    const loadData = async () => {
      console.log('loadData entered');
      loading.value = true;
      const today = new Date();
      const events = await getEventList();
      events.forEach((event) => {
        if (event.date >= today.toISOString().slice(0,10)) {
          eventlist.value.push(event);
        } else {
          pasteventlist.value.push(event);
        }
      })
      loading.value = false;
      console.log('loadData exited');
    }
// ENTRYPOINT
    loadData();

    console.log('AdminEventsContent setup exited');
// EXPOSED PROPERTIES
    return {
      loading,
      eventlist,
      pasteventlist,
      updateUrgent
    };
  }
}
</script>

<style scoped>
p {
  text-align: center;
}

.link {
  color: #f0f8f8;
  text-decoration: none;
}

</style>
