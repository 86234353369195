<template>
  <div :class="event.status+'-border', { urgent: urgent }"  class="flexcontainer eventcontainer">
    <div class="event">
      <div class="left clickable" @click="show = true">
        <p>{{ formatteddate }}</p>
        <p> at {{ formattedstart }}</p>
        <p>{{ event.title }}</p>
        <p :class="event.status+'-text'">{{ event.status.toUpperCase() }}</p>
        <p v-if="event.info" class="note">Note: {{ event.info }}</p>
      </div>

      <div class="right">
        <div v-if="event.status!='cancelled'">
          <p>Can you attend <span v-if="event.type!='other'">(on {{ posforevent }})</span></p>
          <MemberEventPredictedAttendance :mempredatt = "event.memberpredictedattendance" :eid="event.id" :mid="event.memberid" @predattchanged="updatePredictedAttendanceForMember"/>
        </div>
        <div v-else>
          <p>CANCELLED</p>
        </div>
      </div>
    </div>

    <teleport to="#modals" v-if="show">
      <Modal @close="show = !show">
        <template v-slot:header>
          <p class="modalheader">EVENT DETAILS</p>
        </template>
        <template v-slot:body>
          <div class="modalbody">
            <MemberEventDetails :eid="event.id"  :mid="event.memberid" @eventchanged="updateEvent"/>
          </div>
        </template>
      </Modal>
    </teleport>

  </div>
</template>

<script>

import MemberEventPredictedAttendance from '@/components/events/MemberEventPredictedAttendance';
import MemberEventDetails from '@/components/events/MemberEventDetails';
import Modal from '@/components/global/Modal';
import { ref, computed  } from 'vue';

export default {
  name: "MemberSingleEvent",
  props: {
    event: Object
  },
  components: {
    MemberEventPredictedAttendance,
    Modal,
    MemberEventDetails
  },
  emits: [
      'eventchanged',
      'predattchanged'
  ],
  setup(props, { emit }) {
    console.log('SingleEvent setup entered');

// COMPUTED PROPERTIES
    const formatteddate = computed(() => new Date(props.event.date).toDateString());
    const formattedstart = computed(() => props.event.starttime.substring(0,5));

    const urgent = computed(() => {
      console.log('urgent entered');

      if (props.event.urgentuntil === null) {
        console.log('urgent exited');
        return false;
      } else {
        const now = new Date().toISOString().slice(0,10);
        console.log('urgent exited');
        return props.event.urgentuntil >= now;
      }
    });

    const posforevent = computed(() => (props.event.memberpredictedattendance?props.event.memberpredictedattendance.position:props.event.usualposition));

// DATA
    const show = ref(false);

// FUNCTIONS
    const updateEvent = (payload) => {
      console.log('updateEvent entered');
      emit('eventchanged', payload)
      console.log('updateEvent exited');
    }

    const updatePredictedAttendanceForMember = (payload) => {
      console.log('updatePredictedAttendanceForMember entered');
      emit('predattchanged', payload)
      console.log('updatePredictedAttendanceForMember exited');
    }

    console.log('MemberSingleEvent setup exited');
// EXPOSED PROPERTIES
    return {
      formatteddate,
      formattedstart,
      posforevent,
      urgent,
      show,
      updateEvent,
      updatePredictedAttendanceForMember  };
  }
}
/* the event object contains
id,
date,
starttime,
endtime,
location,
type,
status,
title,
urgentuntil,
memberid,
usualposition
memberpredictedattendance
    null if there is no entry for this member
    or {mid, name, position, available (1-No, 2-Yes)}
*/

</script>

<style scoped>

.eventcontainer {
  flex-direction: column;
}

.event {
  border: none;
  display: flex;
  justify-content: space-between;
}

.event div p {
  padding: 3px;
}

.left {
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
  padding: 5px;
  background: #f0f0f0;
  flex: 2 10 60%;
}

.right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px;
  padding: 5px;
  flex: 3 12 40%;
  background: #f0f0f0;
}

.modalheader {
  font-size: larger;
  font-weight: bold;
}

.modalbody {

}

.note {
  font-style: italic;
  font-weight: bolder;
}

.urgent::before {
  content: "!!! Urgent !!!";
  font-size: 1.5rem;
  color: darkorange;
}


</style>


