<template>

  <Navbar />
  <Authorise>
    <template v-slot:viewcontent>
      <AdminEventBulkContent/>
    </template>
  </Authorise>

</template>

<script>
import AdminEventBulkContent from '@/components/viewcontents/AdminEventBulkContent';

export default {
  name: 'AdminEventBulk',
  components: {
    AdminEventBulkContent
  }
};

</script>

<style scoped>

</style>
