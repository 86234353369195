// EXTERNAL PROPERTIES

// DATA
// Client data
const swversion = "Release version 2022-Mar-30"
//const homeurl = "http://localhost:8080";
const homeurl = "https://wgsb.emerynet.uk";
const title = "WGSB availability";
//const title = "WGSB availability TEST";

// Server data
//const baseurl = "http://localhost:8000/";
//const baseurl = "http://server.wgsbtest.emerynet.uk/";
const baseurl = "https://server.wgsb.emerynet.uk/";
const expiry = 10000000; //  about 4 months in seconds
const sessionexpiry = 86400; // 1 day in seconds

// Constants
const positions = [
    'trumpet',
    'trombone',
    'drums',
    'voice',
    'bass',
    '?????'];

const eventtypes = [
    'concert',
    'rehearsal'
];

const eventstatuses = [
    'confirmed',
    'provisional',
    'cancelled'
];

const roles = {
    super: 'Superuser',
    admin: 'Administrator',
    member: 'Member'
};

const activestates = [
    'Not active', // index 0
    'Active', // index 1
]



const useSettings = () => {
    console.log("useSettings entered");

    console.log("useSettings exited");
// EXPOSED PROPERTIES
    return {
        swversion,
        title,
        baseurl,
        homeurl,
        expiry,
        sessionexpiry,
        positions,
        eventtypes,
        eventstatuses,
        roles,
        activestates
    };
}

export default useSettings;
