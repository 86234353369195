import { ref, computed } from 'vue';
import useSettings from '@/composables/useSettings';

// EXTERNAL PROPERTIES
const { baseurl, expiry, sessionexpiry } = useSettings();

// DATA
const loggedinuser = ref(null);
const autherror = ref(null);
const unrecoverable = ref(false);
const stayloggedin = ref(true);

// the token variable is "watched" in app setup
// if its value changes to non-null, it is stored in local/session storage as 'token'
// if its value changes to null the 'token' item in local/session storage is removed
const token = ref((localStorage.getItem('token')?localStorage.getItem('token'):sessionStorage.getItem('token')));

//const unrecoverable = computed(() => autherror === 'System error' || autherror === 'Server not available' );

// FUNCTIONS
const logout = async () => {
    console.log("logout entered");

    loggedinuser.value = null;
    autherror.value = 'You are not currently logged in';
    token.value = null;

    console.log("logout exited");
}

const login = async (email, password) => {
    console.log("login entered");
    // this function calls authenticate to request a token from the server
    // if the credentials are valid, and a token is returned, it is stored in 'token'
    // and calls doDataGet to get the user details from from the server
    // if either function fails, an exception is thrown which is caught here
    // if all is well, 'loggedinuser' is set
    // if there is an error, 'autherror' is set

    try {
        autherror.value = null;
        unrecoverable.value = false;
        loggedinuser.value = null;

        const newtoken = await authenticate(email, password);
        // token value must be set before doDataGet is called
        token.value = newtoken;

        const url = baseurl + 'auth/loggedinuser';
        loggedinuser.value = await doDataGet(url);

    } catch (err) {
        // possible errors are Invalid credentials, You are not currently logged in, System error, Server not available
        if (err.message == 'Invalid credentials' || err.message == 'You are not currently logged in') {
            token.value = null;
        } else {
            unrecoverable.value = true;
        }
        autherror.value = err.message;
    }
    console.log("login exited");
}

const initLoggedinUser = async () => {
    // this function calls doDataGet to get the details from from the server
    // if that function fails, an exception is thrown which is caught here
    // if all is well, 'loggedinuser' is set
    // if there is an error, 'autherror' is set
    console.log("initLoggedinUser entered");
    try {
        autherror.value = null;
        unrecoverable.value = false;
        loggedinuser.value = null;

        const url = baseurl + 'auth/loggedinuser';
        loggedinuser.value = await doDataGet(url);
    } catch (err) {
        // possible errors are You are not currently logged in, System error, Server not available
        if (err.message == 'You are not currently logged in') {
            token.value = null;
        } else {
            unrecoverable.value = true;
        }
        autherror.value = err.message;
    }
    console.log("initLoggedinUser exited");
}

const authenticate =  async (email, password) => {
    // this function accepts email and password and requests a token from the server
    // if a token is received, it is returned to the caller
    // errors from the server, or when the fetch cannot reach the server,
    // are thrown as exceptions
    console.log("authenticate entered");
    // get token from server
    const url = baseurl + 'auth/authenticate';
    const exp = (stayloggedin.value ? expiry : sessionexpiry);
    const credentials = {
        email: email,
        password: password,
        expiry: exp
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(credentials)
    };
    try {
        // send fetch request
        const response = await fetch(url, options);
        if (!response.ok) {
            // response could be 401 or 500
            if (response.status === 401) { // auth error
                throw Error('Invalid credentials');
            } else {
                throw Error('System error');
            }
        }
        const data = await response.json(); // returns an object

        console.log("authenticate exited");
        return data.token;

    } catch (err) {
        // failed to fetch or as thrown above (or from response.json?)
        let errmess = "";
        switch (err.message) {
            case 'Invalid credentials':
            case 'System error':
                errmess = err.message;
                break;
            case 'Failed to fetch':
                errmess = 'Server not available'
                break;
            default:
                errmess = 'System error';
        }
        unrecoverable.value = errmess != 'Invalid credentials';
        throw Error(errmess);
    }
}

const doDataGet = async (url) => {
    // this function does the actual http request
    // it returns data or throws an exception
    // it is in useAuth because it includes some authentication
    // whenever a request is sent to the server (apart from when logging in) it has to include a token
    // if the token is not valid, the server returns a 401, following which
    // the loggedinuser and the token must be invalidated by the caller
    console.log("doDataGet entered");
    try {
        if (!token.value) {
            throw Error('You are not currently logged in');
        } else {
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + token.value
                }
            };
            // send fetch request - exception only if cannot reach server
            const response = await fetch(url, options);
            if (!response.ok) {
                // response could be 401 or 500
                if (response.status === 401) {
                    throw Error('You are not currently logged in'); // caught below
                } else {
                    throw Error('System error'); // caught below
                }
            }
            const data = await response.json(); // returns an object or errors
            console.log("doDataGet exited");
            return data;
        }
    } catch (err) {
        // failed to fetch or as thrown above (or from response.json?)
        let errmess = "";
        console.log('Error message:', err.message);
        switch (err.message) {
            case 'You are not currently logged in':
            case 'System error':
                errmess = err.message;
                break;
            case 'Failed to fetch':
                errmess = 'Server not available'
                break;
            default:
                errmess = 'System error';
        }
        throw Error(errmess);
    }
}

const getData = async (relurl) => {
    // this function either returns data or invalidates the loggedinuser
    console.log("getData entered");
    try {
        autherror.value = null;
        unrecoverable.value = false;

        const data = await doDataGet(baseurl + relurl);
        console.log("getData exited");
        return data;
    } catch (err) {
        // possible errors are You are not currently logged in, System error, Server not available
        if (err.message == 'You are not currently logged in') {
            token.value = null;
            loggedinuser.value = null;
        } else {
            unrecoverable.value = true;
        }
        autherror.value = err.message;
        console.log("getData exited");
    }
}


const doDataPost = async (url, postdata) => {
    // this function does the actual http request
    // it returns something or throws an exception
    // it is in useAuth because it includes some authentication
    // whenever a request is sent to the server (apart from when logging in) it has to include a token
    // if the token is not valid, the server returns a 401, following which
    // the loggedinuser and the token must be invalidated by the caller
    console.log("doDataPost entered", url)
    console.log("POSTDATA: ",postdata)
    try {
        if (!token.value) {
            throw Error('You are not currently logged in');
        } else {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': 'Bearer ' + token.value
                },
                body: JSON.stringify(postdata)
            };
            // send fetch request - exception only if cannot reach server
            const response = await fetch(url, options);
            if (!response.ok) {
                // response could be 401 or 500
                if (response.status === 401) {
                    throw Error('You are not currently logged in'); // caught below
                } else {
                    throw Error('System error'); // caught below
                }
            }
            const result = await response.json(); // returns an object
            console.log("doDataPost exited");
            return result;
        }
    } catch (err) {
        // failed to fetch or as thrown above (or from response.json?)
        let errmess = "";
        switch (err.message) {
            case 'You are not currently logged in':
            case 'System error':
                errmess = err.message;
                break;
            case 'Failed to fetch':
                errmess = 'Server not available'
                break;
            default:
                errmess = 'System error';
        }
        unrecoverable.value = errmess != 'You are not currently logged in';
        throw Error(errmess);
    }
}

const postData = async (relurl, postdata) => {
    // this function either does stuff or invalidates the loggedinuser
    console.log("postData entered", relurl);
    console.log("POSTDATA: ",postdata)
    try {
        autherror.value = null;
        unrecoverable.value = false;

        const result = await doDataPost(baseurl + relurl, postdata);
        console.log("posttData exited");
        return result;
    } catch (err) {
        // possible errors are You are not currently logged in, System error, Server not available
        if (err.message == 'You are not currently logged in') {
            token.value = null;
            loggedinuser.value = null;
        } else {
            unrecoverable.value = true;
        }
        autherror.value = err.message;
        console.log("postData exited");
    }
}

// EXPOSED PROPERTIES
const useAuth = () => {
    console.log("useAuth entered");

    console.log("useAuth exited");
    return {
        token,
        stayloggedin,
        loggedinuser,
        autherror,
        unrecoverable,
        login,
        logout,
        initLoggedinUser,
        getData,
        postData };
}

export default useAuth;