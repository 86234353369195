import {ref} from 'vue';
import useSettings from "@/composables/useSettings";
import useAuth from "@/composables/useAuth";

// EXTERNAL PROPERTIES
const { homeurl } = useSettings();
const { getData, postData, loggedinuser } = useAuth();

// FUNCTIONS
const getEventList = async () =>{
    console.log("getEventList entered");

//    const relurl = 'events';
    let fromdate = new Date();
    fromdate.setMonth(fromdate.getMonth() - 1);
    const from = fromdate.toISOString().slice(0,10);
    const relurl = 'events/all/all/'+from;
    const eventlist =  await getData(relurl);

    console.log("getEventList exited");
    return eventlist;
}

const getEventListForMember = async () => {
    console.log("refreshEventListForMember entered");

    const mid = loggedinuser.value.id;
    const relurl = 'events/formember/' + mid;
    const eventlistformember =  await getData(relurl);

    console.log("refreshEventListForMember exited");
    return eventlistformember;
}

const getEventData = async (eid) => {
    console.log("getEventData entered");

    const relurl = 'event/all/' + eid;
    const event =  await getData(relurl);

    console.log("getEventData exited");
    return event;
}

const getEventDetails = async (eid) => {
    console.log("getEventDetails entered");

    const relurl = 'event/' + eid;
    const event =  await getData(relurl);

    console.log("getEventDetails exited");
    return event;
}

const getEventForMember = async (eid, mid) => {
    console.log("getEventForMember entered");

    const relurl = 'event/formember/' + eid + '/' + mid;
    const eventformember =  await getData(relurl);

    console.log("getEventForMember exited");
    return eventformember;
}


const changeUrgent = async (eid, days) => {
    console.log("changeUrgent entered");

    const url = 'event/urgent/change/' + eid + '/' + days;
    const newvalue = await postData(url, null);

    console.log("changeUrgent exited");
    return newvalue;
}

const changeEvent = async (eid, event) => {
    console.log("changeEvent entered");
    // event is an object
    // eid = 0: new event
    // eid > 0 but event = null: delete event
    // otherwise: change event

    const url = 'event/update/' + eid;
    const newvalue = await postData(url, event);

    console.log("changeEvent exited");
    return newvalue;
}

const getRegisterLinks = (eid) => {
    console.log("getRegisterLinks entered");
    const links = {
        predictedattendancelink: homeurl + '/register/availability/' + eid,
        actualattendancelink: homeurl + '/register/attendance/'+ eid
    }
    console.log("getRegisterLinks exited");
    return links;
}

// EXPOSED PROPERTIES
const useEvents = () => {
    console.log("useEvents entered");

    console.log("useEvents exited");
    return {
        getEventList,
        getEventData,
        getEventDetails,
        getEventForMember,
        changeEvent,
        getEventListForMember,
        changeUrgent,
        getRegisterLinks
    }
}

export default useEvents;
