<template>

  <div :class="event.status+'-border'"  class="flexcontainer eventcontainer">
    <div class="event">

      <div class="left clickable" @click="show = true">
        <p>{{ formatteddate }}</p>
        <p> at {{ formattedstart }}</p>
        <p>{{ event.title }}</p>
        <p :class="event.status+'-text'">{{ event.status.toUpperCase() }}</p>
        <p v-if="event.info" class="note">Note: {{ event.info }}</p>
      </div>

      <div class="right">
        <div class="links">
          <router-link class="button" :to="{name: 'event details', params: {eid: event.id } }">Edit event details</router-link>
          <router-link class="button" :to="{name: 'predicted attendance', params: {eid: event.id } }">Edit predicted attendance</router-link>
        </div>
      </div>
    </div>


  </div>

  <teleport to="#modals" v-if="show">
    <Modal @close="show = !show">
      <template v-slot:header>
        <p class="modalheader">EVENT DETAILS</p>
      </template>
      <template v-slot:body>
        <div class="modalbody">
          <MemberEventDetails :eid="event.id" :mid="'0'" />
        </div>
      </template>
    </Modal>
  </teleport>

</template>

<script>
import AdminUrgentEvent from '@/components/events/AdminUrgentEvent';
import MemberEventDetails from '@/components/events/MemberEventDetails';
import Modal from '@/components/global/Modal';
import {computed, ref} from 'vue';

export default {
  name: "AdminSingleEvent",
  props: {
    event: Object
  },
  components: {
    AdminUrgentEvent,
    MemberEventDetails,
    Modal
  },
  emits: [
    'urgentchanged'
  ],

  setup(props, { emit }) {
    console.log('AdminSingleEvent setup entered');

// COMPUTED PROPERTIES
    const formatteddate = computed(() => new Date(props.event.date).toDateString());
    const formattedstart = computed(() => props.event.starttime.substring(0,5));

// DATA
    const show = ref(false);

// FUNCTIONS
    const urgentChanged = (newvalue) => {
      emit('urgentchanged', newvalue);
    }

    console.log('AdminSingleEvent setup exited');
// EXPOSED PROPERTIES
    return {
      formatteddate,
      formattedstart,
      show,
      urgentChanged
    };
  }
}

</script>

<style scoped>

.eventcontainer {
  flex-direction: column;
}

.event {
  border: none;
  display: flex;
  justify-content: space-between;
}

.event div p {
  padding: 3px;
}

.left {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 5px 5px 0;
  padding: 5px;
  background: #f0f0f0;
  flex: 0 0 60%;
}

.right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px 0 5px 5px;
  padding: 5px;
  flex: 0 0 39%;
  background: #f0f0f0;
}

.note {
  font-style: italic;
  font-weight: bolder;
}

.links {
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.link {
  cursor: pointer;
}
</style>


